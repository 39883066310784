/* You can add global styles to this file, and also import other style files */

@import 'colors'
@import 'icon-stack'

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap'
@import 'bootstrap-icons/font/bootstrap-icons.css'
@import 'animate.css/animate.css'
@import 'prismjs/themes/prism-okaidia.css'
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
@import 'prismjs/plugins/line-highlight/prism-line-highlight.css'
@import 'codemirror/lib/codemirror'
@import 'codemirror/theme/idea.css'
@import 'styles/form-styles'

/* Custom fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap')
// Fira Code
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap')
// Mulish
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')

html, body, app-root
  font-family: 'Inter', sans-serif
  height: 100vh

h1, h2, h3, h4, h5, h6
  font-family: 'Space Grotesk', sans-serif

.cursor-pointer
  cursor: pointer !important

.cursor-text
  cursor: text !important

.cursor-default
  cursor: default !important

.required:after
  content: "*"
  color: $danger
  margin-left: 0.2rem
  vertical-align: sub

.dropdown-no-caret.dropdown-toggle
  &:after
    display: none

.outline-none
  outline: none
  --webkit-box-shadow: none
  --moz-box-shadow: none
  box-shadow: none

  &:focus
    outline: none
    --webkit-box-shadow: none
    --moz-box-shadow: none
    box-shadow: none

code, .CodeMirror
  font-family: 'Fira Code', 'Lucida Console', 'Consolas', monospace


.CodeMirror
  resize: vertical

.wh-100
  width: 100%
  height: 100%

.w-content-100
  width: calc(100% - 72px)

.min-h-content-100
  height: calc(100vh - 74px)
  min-height: calc(100vh - 74px)

.sr-only
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  white-space: nowrap
  border: 0

.textarea-no-resize
  resize: none

.text-code
  font-family: 'Fira Code', 'Lucida Console', 'Consolas', monospace

.assignment-composer.h-auto .CodeMirror
  height: auto !important

.assignment-composer.h-100 .CodeMirror
  height: 100px !important

.CodeMirror-placeholder
  color: $text-muted !important

.animate__animated.animate__300ms
  -webkit-animation-duration: 300ms !important
  animation-duration: 300ms !important

.border-lg-end
  border-right: none

.z-front
  z-index: 1000

.right-caret
  font-size: 16px

  &:after
    content: ""
    display: inline-block
    margin-left: auto
    vertical-align: center
    border-top: 0.3em solid transparent
    border-right: 0
    border-bottom: 0.3em solid transparent
    border-left: 0.3em solid


// Media breakpoint for lg
@include media-breakpoint-up('lg')
  .border-lg-end
    border-right: 1px solid $border-color !important

  .border-lg-start
    border-left: 1px solid $border-color !important


.focused
  color: $input-focus-color
  background-color: $input-focus-bg
  border-color: $input-focus-border-color
  box-shadow: $input-focus-box-shadow


// flex shortcuts
.flex-center
  display: flex
  justify-content: center
  align-items: center


// Transforms
.transform-rotate-90
  transform: rotate(90deg)

.transform-rotate-180
  transform: rotate(180deg)

.transform-rotate-270
  transform: rotate(270deg)

.transform-rotate-360
  transform: rotate(360deg)


.transitioned
  transition: all 0.3s ease-in-out


.x-small
  font-size: 0.8rem

.bg-black-50
  background-color: rgba(0, 0, 0, 0.15) !important


th[appSortable]
  cursor: pointer
  user-select: none
  -webkit-user-select: none

th[appSortable].desc:before
  transform: rotate(180deg)
  -ms-transform: rotate(180deg)


th[appSortable].desc:before,
th[appSortable].asc:before
  content: ''
  display: block
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat
  background-size: 22px
  width: 22px
  height: 22px
  float: left
  margin-left: -22px


.scrollable-content
  max-height: 400px
  overflow-y: auto
  padding-right: 10px
  scrollbar-width: thin
  scrollbar-color: #ccc transparent

  &::-webkit-scrollbar
    width: 8px

  &::-webkit-scrollbar-track
    background: transparent

  &::-webkit-scrollbar-thumb
    background: #ccc
    border-radius: 4px

  &::-webkit-scrollbar-thumb:hover
    background: #aaa

