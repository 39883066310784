.icon-stack
  position: relative
  display: inline-block
  .bi
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  .icon-text
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: x-small
  .icon-badge
    position: absolute
    top: 0
    right: 0
    transform: translate(0%, -110%)
    font-size: x-small
