$primary: #7857FF
$secondary: #010147
$danger: #F64740
$info: #7857FF
$warning: #FD6C01
$success: #00BF94
$light: #f7f6fc
$body-bg-dark: #1C2126
$body-secondary-bg-dark: #292E32

@import "bootstrap/scss/bootstrap"

.bg-blue
  background-color: #3666a4
