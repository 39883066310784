@import '../colors'

.vertical-multistep-form
  position: relative

  .form-step
    padding-left: 2.25rem
    margin-bottom: 2.15rem
    position: relative
    .form-step-progress
      position: absolute
      background-color: $primary-bg-subtle
      height: 100%
      width: 1.15rem
      top: 2.75rem
      left: .6rem
      border-bottom-right-radius: 1rem
      border-bottom-left-radius: 1rem
      z-index: -1
      .form-step-progress-bar
        height: 0
        width: 100%
        background-color: $primary
        border-bottom-right-radius: 1rem
        border-bottom-left-radius: 1rem
        transition: height 250ms ease-in-out
    .form-step-header
      display: flex
      flex-direction: row
      align-items: start
      justify-content: start
      margin-left: -2.6rem
      .form-step-details
        .form-step-title
          font-size: 1.25rem
          font-weight: bold
      .form-step-number
        display: flex
        align-items: center
        justify-content: center
        font-size: 1.5rem
        width: 2rem
        height: 2rem
        padding: 1.5rem
        background-color: $primary-bg-subtle
        color: $light
        border-radius: 50%
        margin-right: 1.2rem
        user-select: none
        transition: all 250ms ease-in-out

    .form-step-body
      margin-left: 1.5rem

    &.active
      .form-step-number
        transform: scale(1.15)
        background-color: $primary

    &.complete
      .form-step-number
        background-color: $primary
      .form-step-progress-bar
        height: 100%

